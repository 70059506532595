import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Box, Grid, Heading, HStack, Input, Stack, Text,Container,Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent,
  DrawerCloseButton, useDisclosure,Button,Avatar, Spacer, Flex  ,Alert,AlertIcon,BreadcrumbItem,Breadcrumb,BreadcrumbLink,Select,FormLabel } from '@chakra-ui/react'
import { CheckboxFilter } from './CheckboxFilter'
import { ColorPicker } from './ColorPicker'
import { PriceRangePicker } from './PriceRangePicker'
import { ProductBreadcrumb } from './ProductBreadcrumb'
import { SizePicker } from './SizePicker'
import { SortbySelect } from './SortBySelect'
import { MobileFilter } from './MobileFilter'
import { blueFilters, colorFilter, genderFilter, sizeFilter } from './_data'
import DynamicButton from '../DynamicButton'
import ProductList from '../ProductList'
import AttributeMaster from '../AttributeMaster';
import CustomFieldMaster from '../CustomFieldMaster';
import http from "../../http-common";
import swal from 'sweetalert';
import { useDispatch,useSelector } from 'react-redux';
import {addToProducts,filterProductCategory} from '../../redux/slices/productSlice';
import { useForm, useFormContext, FormContext } from "react-hook-form";
import { AppGrid } from '../ProductGrid/AppGrid';
import { Header } from '../../header/Header';
import Footer from '../../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { FiChevronRight,FiMinus, FiPlus } from 'react-icons/fi'
import LoadingSpinner from '../../spiiner/LoadingSpinner';
export const Filter = () => {
    document.title = 'Home/Product List';
    const products_arr = useSelector((state) => state.products.products);
    const setproducts_arr = useSelector((state) => state.products.setProducts);
    const companysetting  = useSelector((state) => state.company);
    const brandMaster  = useSelector((state) => state.brand);
    const { register,handleSubmit,setValue,getValues,watch,reset, formState: { errors } } = useForm();
    const [minVal, setMinVal] = useState(0);
    const [maxVal, setMaxVal] = useState(0);
    const [selectBrand, setSelectBrand] = useState(0);
    const [products, setProducts] = useState([]);
    const [selproducts, setSelProducts] = useState([]);
    const [searchattributes, setSearchAttributes] = useState([]);
    const [searchcustoms, setSearchCustoms] = useState([]);
    const [loading,setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef()
    const baseurl = useSelector(state => state.baseurl.baseURL);; //window.location.origin; //"https://gadgets.ondemandcrm.co"; 
    let { categoryname } = useParams();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const IMAGE = `${baseurl}/public/images/MedBot/no_product_1.png`;
    {/* /ecommerce Remove  */}
    const breadcrumbData = [
      {
        label: 'Home',
        slug: '/',
      },
      {
        label: categoryname.replaceAll('-',' '),
        slug: categoryname.replaceAll('-',' '),
      }
    ]
    useEffect(()=>{
        window.scrollTo(0, 0);
    },['']);
    useEffect(() => {
      //console.log(categoryname);
      setLoading(true);
      /*const spinner = document.getElementById("spinner");
      if(spinner){
          spinner.style.display = "flex";
      }*/
      async function getProductCategoryList() {
          let url = '/ecommerce_api/getCategorywiseProductList/';
          const res = await http.post(url, {
              AUTHORIZEKEY: "",
              category_name: categoryname.replaceAll("-"," "),
          });
          if (res.data[0].STATUS == "SUCCESS") {
              dispatch(addToProducts(res.data[0].DATA.products));
              /*if(spinner){
                  spinner.style.display = "none";
              }*/
              setLoading(false);
          } else {
            dispatch(addToProducts([]));
              /*if(spinner){
                  spinner.style.display = "none";
              }*/
              setLoading(false);
          }
      }
      getProductCategoryList();
  }, [categoryname]);

  useEffect(() => {
      setProducts(products_arr);
      setSelProducts(setproducts_arr);
      setIsLoaded(true);
  }, [setproducts_arr]);
  const onSubmit = (data) => {
        http.post(`/ecommerce_api/getProductFilterSearchList/`, {
            moredata: data,
            category_name: categoryname.replaceAll("-"," "),
            minprice:minVal,
            maxprice:maxVal,
            type:'D',
            brand_id:selectBrand

        }).then(res => {
          if (res.data[0].STATUS == "SUCCESS") {
              setSelProducts(res.data[0].DATA.products);
              onClose();
          } else {
              setSelProducts([]);
              onClose();
          }
        }).catch(err => {
          swal("Something went wrong", "error");
        });
    }
    const getAttributeMaster = async () => {
        try {
            const res = await http.post('/ecommerce_api/getAttributeMaster/', {
                AUTHORIZEKEY: "",
                category_name: categoryname.replaceAll("-"," "),
            });
            if (res.data[0].STATUS == "SUCCESS") {
              //console.log(res.data[0].DATA.attribute_arr);
              setSearchAttributes(res.data[0].DATA.attribute_arr);
            } else {
              setSearchAttributes([]);
            }
        } catch (error) {
            console.error(error); // You might send an exception to your error tracker like AppSignal
            return error;
        }
    }
    useEffect(() => {
        getAttributeMaster();
    }, [categoryname]);
    const getCustomFieldMaster = async () => {
        try {
            const res = await http.post('/ecommerce_api/getProductCustomFieldMaster/', {
                AUTHORIZEKEY: "",
            });
            if (res.data[0].STATUS == "SUCCESS") {
                setSearchCustoms(res.data[0].DATA.customs);
                onClose();
            } else {
                setSearchCustoms([]);
            }
        } catch (error) {
            console.error(error);
            return error;
        }
    }
    useEffect(() => {
        getCustomFieldMaster();
    }, []);

    const handleResetFilter = (e) => {
        window.location.reload();
        // setSelProducts(setproducts_arr);
        // reset({});
        // onClose();
    }
    const handleSubmitFilter = (e,type) =>{
      let minval = minVal;
      let maxval = maxVal;
      if(type == 'mx'){
        maxval = e.target.value;
      }
      if(type == 'mi'){
        minval = e.target.value;
      }
      if(type == 'A'){
      //  setValue(e.target.name, e.target.value);
      }
      //console.log(minval);console.log(maxval);
      //const values = getValues();
      //console.log(values);
      http.post(`/ecommerce_api/getProductFilterSearchList/`, {
          moredata: getValues(),
          category_name: categoryname.replaceAll("-"," "),
          minprice:minval,
          maxprice:maxval,
          type:'D'
      }).then(res => {
        if (res.data[0].STATUS == "SUCCESS") {
            setSelProducts(res.data[0].DATA.products);
            onClose();
        } else {
            setSelProducts([]);
            onClose();
        }
      }).catch(err => {
        swal("Something went wrong", "error");
      });
    }
  return (
    !loading ? (
    <>
    <Header/>
    <Box
    maxW="8xl"
    mx="auto"
    px={{
      base: '4',
      md: '8',
      lg: '12',
    }}
    py={{
      base: '6',
      md: '8',
      lg: '12',
    }}
  >
    {/* <ProductBreadcrumb data={breadcrumbData} /> */}
    <Breadcrumb mb={5} spacing='8px' separator={<FiChevronRight color='gray.500' style={{marginTop:"4px"}} />}>
        <BreadcrumbItem>
          {/* /ecommerce  remove */}
          <BreadcrumbLink 
            onClick={()=>navigate('/')}
            >Home</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href='#'>{categoryname.replaceAll('-',' ')}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    <Box
      mt={{
        base: '2',
        md: '6',
      }}
    >
      <Grid
        templateColumns={{
          base: '1fr',
          md: '1fr', //add 240px before 1fr
        }}
        gap="14"
      >
        {/* <Stack
          spacing="10"
          maxW="240px"
          display={{
            base: 'none',
            md: 'flex',
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
          
          {brandMaster.brands.length > 0  && 
          <Stack spacing="1">
            <FormLabel mt={2} fontWeight="semibold" as="legend" mb="0">
              Brand
            </FormLabel>
            <HStack > 
              <Select placeholder='Select option' size='sm' onChange={(e)=>setSelectBrand(e.target.value)}>
              {(brandMaster.brands.length > 0) && brandMaster.brands.map((bd) =>
                  <option value={bd.value}>{bd.label}</option>
                )}
              </Select>
            </HStack>
          </Stack>
          }
          {(companysetting.length > 0) && 
              <AttributeMaster  spacing="3"  register={register} attributes={searchattributes} />
          }
          <Stack spacing="1">
            <FormLabel mt={2} fontWeight="semibold" as="legend" mb="0">
              Price range
            </FormLabel>
            <HStack spacing="2">
              <Input type="number" placeholder="500"  onBlur={(e)=>{setMinVal(e.target.value > 0 ? e.target.value : 0)}}/>
              <Input type="number" placeholder="1,000" onBlur={(e)=>{setMaxVal(e.target.value > 0 ? e.target.value : 0)}}/>
            </HStack>
          </Stack>
          <Button mt={4} type="submit" borderRadius={0} colorScheme='blue' size='md'>Search</Button>
          <Button mt={4} onClick={handleResetFilter} borderRadius={0} ml={4} colorScheme='green' size='md'>Reset</Button>
          </form>
        </Stack> */}
        <Box width="full">
          <Stack
            spacing={{
              base: '6',
              md: '4',
            }}
            direction={{
              base: 'column',
              md: 'row',
            }}
            justify="space-between"
            align="flex-start"
            width="full"
          >
            <Stack
              direction={{
                base: 'column',
                md: 'row',
              }}
              align="baseline"
            >
              <Heading size="md" fontSize="2xl">
                {categoryname.replaceAll('-',' ')}
              </Heading>
              {/* <Text color="gray.500">(300 products)</Text> */}
            </Stack>
            {/* <MobileFilter companysetting={companysetting} attributes={searchattributes} category={categoryname.replaceAll("-"," ")} setSelProducts={setSelProducts} brandMaster={brandMaster}/> */}
            <HStack
              display={{
                base: 'none',
                md: 'flex',
              }}
            >
              <Text flexShrink={0} color="gray.500" fontSize="sm">
                Sort by
              </Text>
              <SortbySelect />
            </HStack>
          </Stack>
          <Box mt="4" minH="480px">
            {selproducts.length > 0 ? <AppGrid products={selproducts} type="F" /> : <Alert status='warning'> <AlertIcon /> Product Not Available </Alert>}
          </Box>
        </Box>
      </Grid>
    </Box>
    <DynamicButton/>
  </Box>
  <Footer/>
    </>
    ) : (<><LoadingSpinner/></>)
  )
}
