import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Text,
  Button,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { AppProduct } from './ProductDetail/AppProduct';
import DynamicButton from './DynamicButton';
import http from '../http-common';
import { Base64 } from 'js-base64';
import { Header } from '../header/Header';
import Footer from '../Footer/Footer';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../spiiner/LoadingSpinner';
import { Helmet } from 'react-helmet';
import { FaFilePdf } from 'react-icons/fa';
import DisplayHTML from '../Basic/DisplayHTML';
import Annexure from './AnnexureDetails/Annexure';
const tabData = [
  {
    label: 'Nigerian Jollof',
    content: 'Perhaps the greatest dish ever invented.',
  },
];

export default function ProductDetail() {
  let { product_id } = useParams();
  const [proddetails, setProductDetails] = useState([]);
  const [attr, setAttr] = useState([]);
  const [attrmaster, setAttrMaster] = useState([]);
  const [images, setImages] = useState([]);
  const [arr_files, setArrayFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const companysetting  = useSelector((state) => state.company);
  const label_annexure = companysetting.length > 0 && companysetting[0].DATA.label_annexure != null ? companysetting[0].DATA.label_annexure : "";
  const baseurl = useSelector(state => state.baseurl.baseURL); //window.location.origin; //"https://gadgets.ondemandcrm.co";
  async function getSingleProductByName() {
    setLoading(true);
    // const spinner = document.getElementById("spinner");
    // if(spinner){
    //     spinner.style.display = "flex";
    // }
    let url = '/ecommerce_api/getSingleProductByName/';
    const res = await http.post(url, {
      AUTHORIZEKEY: '',
      product_id: Base64.decode(product_id),
    });
    if (res.data[0].STATUS == 'SUCCESS') {
      setProductDetails(res.data[0].DATA.products);
      setAttr(res.data[0].DATA.array_attr);
      setImages(res.data[0].DATA.gallery);
      setAttrMaster(res.data[0].DATA.array_master);
      setArrayFiles(res.data[0].DATA.documentfile_arr);
      // if(spinner){
      //   spinner.style.display = "none";
      // }
      setLoading(false);
    } else {
      setProductDetails([]);
      setAttr([]);
      setImages([]);
      setAttrMaster([]);
      setArrayFiles([]);
      // if(spinner){
      //   spinner.style.display = "none";
      // }
      setLoading(false);
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, ['']);
  useEffect(() => {
    getSingleProductByName();
  }, []);
  return !loading ? (
    <>
      <Header />
      <Container maxW={'7xl'}>
        <AppProduct
          product_id={Base64.decode(product_id)}
          proddetails={proddetails}
          images={images}
          attr={attr}
          attrmaster={attrmaster}
        />
        {arr_files?.length > 0 ? (
          <Box
            maxW="7xl"
            mx="auto"
            className="Ecomm-Group"
            px={{
              base: '4',
              md: '8',
              lg: '12',
            }}
            py={{
              base: '6',
              md: '8',
              lg: '12',
            }}
          >
            <Tabs>
              <TabList>
                <Tab key="1">View Brochure</Tab>
                <Tab key="2"><Annexure label_annexure={label_annexure} /></Tab>
              </TabList>
              <TabPanels>
                <TabPanel p={4} key="1">
                  <TableContainer>
                    <Table size="sm" variant="striped">
                      <Thead>
                        <Tr>
                          <Th>File Name</Th>
                          <Th>Type</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {arr_files.map(item => (
                          <Tr key={item.id}>
                            <Td>{item.file_name}</Td>
                            <Td>{item.file_mime}</Td>
                            <Td>
                              <Text>
                                <Button
                                  size="lg"
                                  onClick={() => window.open(item.file_path)}
                                  colorScheme="red"
                                  variant="link"
                                >
                                  <FaFilePdf />
                                </Button>
                              </Text>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </TabPanel>
                <TabPanel p={4} key="2">
                  <DisplayHTML htmlContent={proddetails.annexure_desc} />
                  {/* <div dangerouslySetInnerHTML={{ __html: proddetails.annexure_desc }}></div>
          {proddetails.annexure_desc} */}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        ) : (proddetails.annexure_desc && (
            <Box
            maxW="7xl"
            mx="auto"
            className="Ecomm-Group"
            px={{
              base: '4',
              md: '8',
              lg: '12',
            }}
            py={{
              base: '6',
              md: '8',
              lg: '12',
            }}
          >
              <Tabs>
              <TabList>
                <Tab key="2"><Annexure label_annexure={label_annexure} /></Tab>
              </TabList>
              <TabPanels>
                <TabPanel p={4} key="2">
                  <DisplayHTML htmlContent={proddetails.annexure_desc} />
                </TabPanel>
              </TabPanels>
            </Tabs>
            </Box>
          )
        )}
      </Container>
      <Footer />
    </>
  ) : (
    <>
      <LoadingSpinner />
    </>
  );
}
