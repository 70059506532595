import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../Footer/Footer';
import { Header } from '../header/Header';
import { changeCategoryLabel } from '../redux/slices/categorySlice';
import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  color,
} from '@chakra-ui/react';
import './contactus.css';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';
import { MdLocationOn } from 'react-icons/md';

const Contactus = () => {
  const dispatch = useDispatch();
  const companysetting = useSelector(state => state.company);
  //console.log(companysetting);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(changeCategoryLabel(''));
    const spinner = document.getElementById('spinner');
    if (spinner) {
      setTimeout(() => {
        spinner.style.display = 'none';
      }, 1500);
    }
  }, ['']);

  return (
    <>
      <Header />
      <Box as={Container} maxW="7xl" mt={2} p={4} minH={'750px'}>
      <Box mt={20}>
        <Heading as="h1" size="xl" textAlign="center">
          Get in touch
        </Heading>
        <Heading as="h1" size="sm" textAlign="center" mt={2}>
          Our Friendliest Professionals are Ready to Assist You 24/7.
        </Heading>
      </Box>
      {companysetting.length > 0 &&
        companysetting.map(value => {
          return (
            <Grid
              templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
              gap={6}
              mx={10}
              mt={20}
            >
              <GridItem
                w="100%"
                border="1px"
                borderColor="gray.200"
                p={14}
                _hover={{ bg: 'white', boxShadow: 'xl' }}
              >
                <Flex gap={3}>
                  <Icon
                    as={MdLocationOn}
                    w={8}
                    h={8}
                    style={{ color: `${value?.DATA?.theme_maincolor}` }}
                  />
                  <Box>
                    <Box>
                      <Heading
                        size="md"
                        style={{ color: `${value?.DATA?.theme_maincolor}` }}
                      >
                        Office address
                      </Heading>
                    </Box>
                    <Text mt={2}>
                      {value?.DATA?.company?.Companyinfo?.address_1},
                      {value?.DATA?.company?.Companyinfo?.address_2},
                      {value?.DATA?.company?.Companyinfo?.area},<br />
                      {value?.DATA?.company?.Companyinfo?.state},
                      {value?.DATA?.company?.Companyinfo?.country}-
                      {value?.DATA?.company?.Companyinfo?.pincode}
                    </Text>
                  </Box>
                </Flex>
              </GridItem>
              <GridItem
                w="100%"
                border="1px"
                borderColor="gray.200"
                p={14}
                _hover={{ bg: 'white', boxShadow: 'xl' }}
              >
                <Flex gap={3}>
                  <PhoneIcon
                    w={8}
                    h={8}
                    style={{ color: `${value?.DATA?.theme_maincolor}` }}
                  />
                  <Box>
                    <Box>
                      <Heading
                        size="md"
                        style={{ color: `${value?.DATA?.theme_maincolor}` }}
                      >
                        Telephone number
                      </Heading>
                    </Box>
                    <Text mt={2}>
                      <a
                        href={`tel:${value?.DATA?.company?.Companyinfo?.office_phone1}`}
                      >
                        +91 - {value?.DATA?.company?.Companyinfo?.office_phone1}
                      </a>
                      <br />
                      <a
                        href={`tel:${value?.DATA?.company?.Companyinfo?.office_phone2}`}
                      >
                        +91 - {value?.DATA?.company?.Companyinfo?.office_phone2}
                      </a>
                    </Text>
                  </Box>
                </Flex>
              </GridItem>
              <GridItem
                w="100%"
                border="1px"
                borderColor="gray.200"
                p={14}
                _hover={{ bg: 'white', boxShadow: 'xl' }}
              >
                <Flex gap={3}>
                  <EmailIcon
                    w={8}
                    h={8}
                    style={{ color: `${value?.DATA?.theme_maincolor}` }}
                  />
                  <Box>
                    <Box>
                      <Heading
                        size="md"
                        style={{ color: `${value?.DATA?.theme_maincolor}` }}
                      >
                        Email address
                      </Heading>
                    </Box>
                    <Text mt={2}>
                      <a
                        href={`mailto:${value?.DATA?.company?.Companyinfo?.email_address}`}
                      >
                        {value?.DATA?.company?.Companyinfo?.email_address}
                      </a>
                    </Text>
                  </Box>
                </Flex>
              </GridItem>
            </Grid>
          );
        })}
        </Box>
      <Footer />
    </>
  );
};

export default Contactus;
