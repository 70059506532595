import React, { useEffect, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ChakraProvider, theme } from '@chakra-ui/react';
import Home from './component/Home';
import Category from './component/Category';
import ProductList from './component/ProductList';
import ProductDetail from './component/ProductDetail';
import DynamicButton from './component/DynamicButton';
import Cart from './component/Cart';
import BeforeCart from './component/BeforeCart';
import http from '../src/http-common';
import CustomizeCake from './component/CustomizeCake';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveCompany, changeCompanyLabel } from './redux/slices/company';
import { addToCategory } from './redux/slices/categorySlice';
import { addTimeSlot } from './redux/slices/timeslotSlice';
import { addBrandMaster } from './redux/slices/brandSlice';
import { Filter } from './component/FiltersWithSidebar/Filter';
import { NotFound } from './component/NotFound';
import { ProductSearch } from './component/ProductSearch';
import { CheckoutDetails } from './checkout/CheckoutDetails';
import { CheckoutPage } from './checkout/CheckoutPage';
import AccountLayout from './Account/AccountLayout';
import { Profile } from './Account/Profile';
import TermdandCondition from './component/TermdandCondition';
import PrivacyPolicy from './component/PrivacyPolicy';
import RefundPolicy from './component/RefundPolicy';
import ShippingPolicy from './component/ShippingPolicy';
import Contactus from './component/Contactus';
import About from './component/About';
import { setBaseURL, setBasehostname } from './redux/slices/baseUrlSlice';
import PaymentStatus from './component/payment/PaymentStatus';
import PaymentCancel from './component/payment/PaymentCancel';
import PaymentForm from './onepaypayment/PaymentForm';
import Prizewheel from './onepaypayment/prizewheel';
import PaymentResponse from './onepaypayment/PaymentResponse';

function App() {
  const AUTHORIZE_KEY = process.env.REACT_APP_AUTHORIZE_KEY;
  const base_url = window.location.origin;
  const base_hosturl = window.location.hostname;
  const dispatch = useDispatch();
  async function getProductCategory(text) {
    let url = '/ecommerce_api/getProductCategoryList/';
    const res = await http
      .post(url, {
        AUTHORIZEKEY: '',
        text: '',
        tree: true,
      })
      .then(res => {
        if (res.data[0].STATUS == 'SUCCESS') {
          dispatch(addToCategory(res.data[0].DATA.categorylist));
        } else {
          dispatch(addToCategory([]));
        }
      });
  }
  async function getLeadTimeSlot() {
    let url = '/ecommerce_api/getLeadTimeSlot/';
    const res = await http
      .post(url, {
        AUTHORIZEKEY: '',
        text: '',
      })
      .then(res => {
        if (res.data[0].STATUS == 'SUCCESS') {
          dispatch(addTimeSlot(res.data[0].DATA.timeslots));
        } else {
          dispatch(addTimeSlot([]));
        }
      });
  }
  async function getBrandMaster() {
    let url = '/ecommerce_api/getBrandMaster/';
    const res = await http
      .post(url, {
        AUTHORIZEKEY: '',
        text: '',
      })
      .then(res => {
        if (res.data[0].STATUS == 'SUCCESS') {
          dispatch(addBrandMaster(res.data[0].DATA.brands));
        } else {
          dispatch(addBrandMaster([]));
        }
      });
  }
  const LINK_TYPE = process.env.REACT_APP_LINK_TYPE;
  const baseURL = useSelector(state => state.baseurl.baseURL);
  const basehostname = useSelector(state => state.baseurl.basehostname);

  useEffect(() => {
    dispatch(setBaseURL("https://gujaratsports.ondemandcrm.co")); //window.location.origin //"https://fashion.ondemandcrm.co"
    dispatch(setBasehostname("gujaratsports.ondemandcrm.co")); //"fashion.ondemandcrm.co" //window.location.hostname
    dispatch(retrieveCompany());
    getProductCategory();
    getLeadTimeSlot();
    getBrandMaster();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Suspense>
        {(() => {
          if (LINK_TYPE == 1) {
            return (
              <>
                <BrowserRouter basename="/ecommerce">
                  {/* <Header /> /ecommerce */}

                  <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route
                      exact
                      path="/filter/:categoryname"
                      element={<Filter />}
                    />
                    <Route exact path="/prizewheel" element={<Prizewheel />} />

                    <Route exact path="/dynamic" element={<DynamicButton />} />
                    <Route exact path="/category" element={<Category />} />
                    <Route exact path="/checkoutcart" element={<Cart />} />
                    <Route exact path="/account" element={<AccountLayout />} />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route
                      exact
                      path="/checkout"
                      element={<CheckoutPage />}
                      //element={<CheckoutDetails />}
                    />
                    <Route
                      exact
                      path="/cart"
                      element={<BeforeCart />}
                      //element={<CheckoutDetails />}
                    />
                    <Route
                      exact
                      path="/customizecake"
                      element={<CustomizeCake />}
                    />
                    <Route
                      exact
                      path="/searchproduct/:productname"
                      element={<ProductSearch />}
                    />
                    <Route
                      exact
                      path="/product_list/:categoryname"
                      element={<ProductList />}
                    />
                    <Route
                      exact
                      path="/product/:product_name/:product_id"
                      element={<ProductDetail />}
                    />
                    <Route
                      exact
                      path="/termsandcondition"
                      element={<TermdandCondition />}
                    />
                    <Route
                      exact
                      path="/privacypolicy"
                      element={<PrivacyPolicy />}
                    />
                    <Route
                      exact
                      path="/refundpolicy"
                      element={<RefundPolicy />}
                    />
                    <Route
                      exact
                      path="/shippingpolicy"
                      element={<ShippingPolicy />}
                    />
                    <Route exact path="/contactus" element={<Contactus />} />
                    <Route exact path="/aboutus" element={<About />} />
                    <Route
                      exact
                      path="/paymentsuccess"
                      element={<PaymentStatus />}
                    />
                    <Route
                      exact
                      path="/paymentcancel"
                      element={<PaymentCancel />}
                    />
                    <Route path="/*" element={<NotFound />} />
                    <Route exact path="/payment" element={<PaymentForm />} />
                    <Route
                      exact
                      path="/paymentresponse"
                      element={<PaymentResponse />}
                    />
                  </Routes>

                  {/* <Footer /> */}
                </BrowserRouter>
              </>
            );
          } else {
            return (
              <>
                <BrowserRouter>
                  {/* <Header /> /ecommerce */}
                  <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route
                      exact
                      path="/filter/:categoryname"
                      element={<Filter />}
                    />
                    <Route exact path="/dynamic" element={<DynamicButton />} />
                    <Route exact path="/category" element={<Category />} />

                    <Route exact path="/checkoutcart" element={<Cart />} />
                    <Route exact path="/payment" element={<PaymentForm />} />
                    <Route
                      exact
                      path="/paymentresponse"
                      element={<PaymentResponse />}
                    />
                    <Route exact path="/prizewheel" element={<Prizewheel />} />
                    <Route exact path="/account" element={<AccountLayout />} />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route
                      exact
                      path="/checkout"
                      element={<CheckoutPage />}
                      //element={<CheckoutDetails />}
                    />
                    <Route
                      exact
                      path="/cart"
                      element={<BeforeCart />}
                      //element={<CheckoutDetails />}
                    />
                    <Route
                      exact
                      path="/customizecake"
                      element={<CustomizeCake />}
                    />
                    <Route
                      exact
                      path="/searchproduct/:productname"
                      element={<ProductSearch />}
                    />
                    <Route
                      exact
                      path="/product_list/:categoryname"
                      element={<ProductList />}
                    />
                    <Route
                      exact
                      path="/product/:product_name/:product_id"
                      element={<ProductDetail />}
                    />
                    <Route
                      exact
                      path="/termsandcondition"
                      element={<TermdandCondition />}
                    />
                    <Route
                      exact
                      path="/privacypolicy"
                      element={<PrivacyPolicy />}
                    />
                    <Route
                      exact
                      path="/refundpolicy"
                      element={<RefundPolicy />}
                    />
                    <Route
                      exact
                      path="/shippingpolicy"
                      element={<ShippingPolicy />}
                    />
                    <Route exact path="/contactus" element={<Contactus />} />
                    <Route exact path="/aboutus" element={<About />} />
                    <Route
                      exact
                      path="/shippingpolicy"
                      element={<ShippingPolicy />}
                    />
                    <Route
                      exact
                      path="/paymentsuccess"
                      element={<PaymentStatus />}
                    />
                    <Route
                      exact
                      path="/paymentcancel"
                      element={<PaymentCancel />}
                    />
                    <Route path="/*" element={<NotFound />} />
                  </Routes>
                  {/* <Footer /> */}
                </BrowserRouter>
              </>
            );
          }
        })()}
      </Suspense>
    </ChakraProvider>
  );
}
export default App;
